import { CardFaceData, CardFacePreviewImage } from '../../../global-types';
import { PreviewImage } from '../../../global-types/images';

/**
 * Gets image to display in thumbnail in card select bar
 *
 * @param face - Card face, either in the editor or in the preview view
 * @returns string | undefined
 */
export const getThumbnailImage = (face: CardFaceData | CardFacePreviewImage): string | undefined => {
  if ('photoZoneTemplate' in face && face.photoZoneTemplate) {
    return face.photoZoneTemplate;
  } else if ('backgroundImage' in face && face.backgroundImage) {
    return `${face.backgroundImage}?w=70`;
  } else if ('dataURL' in (face as unknown as PreviewImage)) {
    // For FE previews
    return (face as unknown as PreviewImage).dataURL;
  }
  return undefined;
};
