import React, { useCallback, useEffect, useState, createRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { fabric } from 'fabric';
import { Pill } from '@hallmark/web.core.buttons.pill';
import { FabricCanvas, useFabric } from '@hallmark/web.core.display.fabric-canvas';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import {
  useAppContext,
  setIsTextDrawerOpen,
  setAllDrawersClosed,
  setIsImageUploadToPhotoZoneDrawerOpen,
  setIsStickerPickDrawerOpen,
  setIsToasterOpen,
  setIsRotationDrawerOpen,
  setIsSystemErrorOpen,
  setIsWamDrawerOpen,
  setIsImageEditDrawerOpen,
} from '../../context/app-context';
import { ImageEditDrawerModes } from '../../context/app-context/app-context-types';
import { useCardContext, setSelectedPhotoZone } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import {
  FabricObject,
  CustomFabricObject,
  ObjectJson,
  EditableTextSettings,
  FabricTextBox,
  CustomFabricImage,
} from '../../global-types';
import { CardType } from '../../global-types/card';
import {
  useActiveCanvas,
  useCanvasScalingFactor,
  useDimensionsWithBleed,
  useElementSize,
  useFeatureFlags,
  useIsOneToMany,
} from '../../hooks';
import { config } from '../../regional-config';
import colorsList from '../../styles/util.scss';
import colors from '../../styles/util.scss';
import {
  clipObject,
  lockEditableSize,
  CanvasDataTypes,
  getObjectByName,
  toggleTamWarning,
  getGroupedItemByName,
  addUserTextHandlers,
  expandText,
  getMaxWidthLine,
  hideMiddleControls,
  isEditableText,
  mergeLines,
  MIN_FONT_SIZE,
  shrinkText,
  getCardFaceClipPath,
  setEditableTextHandlers,
  getSizeAfterScaling,
  getEditableTextButton,
  handleTemporaryTextEditor,
} from '../../utils';
import { findTextById } from '../../utils/utility/find-text-by-id';
import { useImageUploadHandlers } from '../card-controls/drawer-container/hooks/useImageUploadHandlers';
import { getTextSettings, addEditableAreas, removeEditableAreaButtons } from '../card-editor/utils';
import { CardFaceProps } from './card-face-types';
import styles from './card-face.module.scss';
import { calculateAdjustedScale, setCroppedSrc } from './utils';
import { registerZoomEvent, resetZoom } from './utils/zoom-mobile-text';

export const CardFace = ({
  cardFace,
  parentRef,
  useOverrideRef,
  addClass,
  cardIndex,
}: CardFaceProps): React.ReactElement => {
  const classes = classNames(styles['card-face'], addClass);
  const { cardState, cardDispatch } = useCardContext();
  const { appState, appDispatch } = useAppContext();
  const activeCanvas = useActiveCanvas();
  const { initializedDataState } = useInitializationDataContext();
  const isOneToMany = useIsOneToMany();
  const projectTypeCode = initializedDataState.data?.project_type_code;
  const [hasEditingStarted, setHasEditingStarted] = useState(false);
  const showInstructions = projectTypeCode === CardType.SAS && !hasEditingStarted;
  const { isUK } = initializedDataState;
  const [containerRef] = useElementSize();
  const instructionsRef = useRef<HTMLDivElement | null>(null);
  const { openImageEditDrawerByCanvasDataType } = useImageUploadHandlers();

  const { getCanvasDimensionsWithBleed, getImageDimensionsWithBleed } = useDimensionsWithBleed();
  const { t } = useTranslation();
  const { DYNAMIC_TEXBOXES, DISPLAY_ZOOM_DEV, IS_FE_CROPPING_ENABLED } = useFeatureFlags();
  const canvasDimensionsWithBleed = getCanvasDimensionsWithBleed(cardFace);
  const imageDimensionsWithBleed = getImageDimensionsWithBleed(cardFace);
  const isMobile = appState.isMobileApp;

  const isAndroid = /Android/i.test(navigator.userAgent);
  let initialViewportHeight: number;

  const onEditableTextChange = (textbox: fabric.Textbox) => {
    const { fixedWidth, maxFontSize, maxLines } = textbox.data;
    if (textbox.textLines.length <= maxLines && getMaxWidthLine(textbox) < fixedWidth) {
      while (
        textbox.textLines.length <= maxLines &&
        textbox.fontSize &&
        textbox.fontSize < maxFontSize &&
        getMaxWidthLine(textbox) < fixedWidth
      ) {
        expandText(textbox, maxFontSize);
      }
    }
    if (textbox.textLines.length > maxLines || getMaxWidthLine(textbox) > fixedWidth) {
      const hasEndingNewLine = /\n$/.test(textbox.text || '');
      if (hasEndingNewLine) {
        mergeLines(textbox);
      }
      while (textbox.textLines.length > maxLines || getMaxWidthLine(textbox) > fixedWidth) {
        shrinkText(textbox);
        if (
          textbox.fontSize === MIN_FONT_SIZE &&
          (textbox.textLines.length > maxLines || getMaxWidthLine(textbox) > fixedWidth)
        ) {
          const textLength = textbox.text?.length || 0;
          const offset = textbox.selectionEnd || textLength;
          textbox.removeChars(offset - 1, offset);
          setIsToasterOpen(appDispatch, {
            variant: ToastVariants.Warning,
            children: t('editableText.toastWarningDescription'),
            title: t('editableText.toastWarningTitle'),
          });
          if (textbox.hiddenTextarea) {
            textbox.hiddenTextarea.value = textbox.text as string;
          }
          textbox.setSelectionStart(offset - 1);
          textbox.setSelectionEnd(offset - 1);
          textbox.width = fixedWidth;
        }
      }
    }
    textbox.width = fixedWidth;
  };

  const onObjectSelection = useCallback(
    (selected: fabric.Object) => {
      // Hide resize controls when canResizeTextArea flag is set to false.
      if (selected.type === 'textbox') {
        const textZone = selected as FabricTextBox;
        if (textZone.CanResizeTextArea === false) {
          textZone.setControlVisible('resizeControl', false);
          textZone.setControlsVisibility({
            mr: false,
            ml: false,
            mb: false,
            mt: false,
            bl: false,
            mtr: false,
            tr: false,
            tl: false,
            br: false,
          });
        } else {
          textZone.setControlVisible('resizeControl', true);
          textZone.setControlsVisibility({
            mr: config.customControls?.controlsPositionsTextVisibility.mr,
            ml: config.customControls?.controlsPositionsTextVisibility.ml,
            bl: config.customControls?.controlsPositionsTextVisibility.bl,
            mtr: config.customControls?.controlsPositionsTextVisibility.mtr,
            tr: config.customControls?.controlsPositionsTextVisibility.tr,
            tl: config.customControls?.controlsPositionsTextVisibility.tl,
            br: config.customControls?.controlsPositionsTextVisibility.br,
            mt: config.customControls?.controlsPositionsTextVisibility.mt,
            mb: config.customControls?.controlsPositionsTextVisibility.mb,
          });
        }

        if (textZone.CanDeleteTextArea === false) {
          textZone.setControlVisible('deleteControl', false);
          textZone.setControlsVisibility({
            mtr: false,
          });
        } else {
          textZone.setControlVisible('deleteControl', true);
          textZone.setControlsVisibility({
            mtr: true,
          });
        }
      }
      // Drawer should not be open when the user clicks on a text zone.
      if (selected?.data?.type === CanvasDataTypes.EditableText && !DYNAMIC_TEXBOXES) {
        return setAllDrawersClosed(appDispatch);
      }
      if (selected.type === 'textbox') {
        setAllDrawersClosed(appDispatch);
        setIsTextDrawerOpen(appDispatch);
      }

      if (selected.name?.startsWith(CanvasDataTypes.PhotoTextZone)) {
        const photoTextZone = selected as fabric.Group;
        const originalTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZone) as fabric.Text;
        if (originalTextbox && originalTextbox.text && originalTextbox.text.length > 0) {
          setIsTextDrawerOpen(appDispatch);
        } else {
          setAllDrawersClosed(appDispatch);
        }
      }
      openImageEditDrawerByCanvasDataType(selected?.data?.type);

      if (selected.data.type === ImageEditDrawerModes.UserImage) {
        setIsImageEditDrawerOpen(appDispatch, ImageEditDrawerModes.UserImage);
      } else if (selected.data.type === ImageEditDrawerModes.StickerImage) {
        setIsImageEditDrawerOpen(appDispatch, ImageEditDrawerModes.StickerImage);
      }
    },
    [appDispatch],
  );

  const canvasRef = useOverrideRef ? createRef<fabric.Canvas>() : cardFace.canvas;

  const canvas = useFabric({
    canvasRef,
    canvasParentRef: parentRef,
    canvasSettings: {
      preserveObjectStacking: true,
      enableRetinaScaling: true,
      selection: false,
    },
    onReady: (canvas) => {
      /** Event handler for zooming on mobile devices */
      if (isMobile) {
        registerZoomEvent(canvas, isMobile, DISPLAY_ZOOM_DEV);
      }
      /** Resets the zoom out of the canvas */
      canvas.on('selection:cleared', () => {
        setAllDrawersClosed(appDispatch);
        resetZoom(canvas);
      });

      canvas.on('mouse:up', (options) => {
        const editableAreaObject = canvas
          .getObjects()
          .find((el) => el?.data?.type === 'editable-area') as CustomFabricObject;
        //  handles the uploading image for a photo zone
        if (options?.target?.data?.type === CanvasDataTypes.PhotoZoneButton) {
          setSelectedPhotoZone(cardDispatch, options.target.data.photoZoneId);
          setIsImageUploadToPhotoZoneDrawerOpen(appDispatch);
        } else if (options?.target?.data?.type === CanvasDataTypes.UserZoneAddStickerButton) {
          setSelectedPhotoZone(cardDispatch, options.target.data.photoZoneId);
          setIsStickerPickDrawerOpen(appDispatch);
        } else if (options?.target?.data?.type === CanvasDataTypes.UserZoneAddTextButton) {
          // Adds an editable text box to the user zone
          if (canvas && canvasRef.current) {
            const INITIAL_TEXT_MESSAGE = `${t('cardEditor.userTextDefaultMessage')}`;
            const cardFaceClipPath = getCardFaceClipPath(cardState.cardFacesList[`${cardIndex}`], 0);
            const defaultColor = config?.canvas?.textColor ?? colorsList[BrandColors.Black];
            const defaultFontSize = initializedDataState.data?.font_collection.default_size || 50;
            const defaultTextSettings = getTextSettings({
              cardState,
              color: defaultColor,
              defaultFontSize,
              isUK,
              name: CanvasDataTypes.UserText,
            });
            const textWidth = cardFaceClipPath.width;
            const textElement = new fabric.Textbox(INITIAL_TEXT_MESSAGE, {
              top: 100,
              left: 100,
              width: textWidth,
              height: 100,
              hasControls: true,
              hasBorders: config.canvas?.hasBorder,
              borderColor: config.canvas?.borderColor,
              textAlign: 'center',
              ...defaultTextSettings,
            });
            textElement.set({ data: { type: CanvasDataTypes.UserText } });
            if (cardFaceClipPath) {
              textElement.set({
                // 62 is default textBox height
                top: cardFaceClipPath.top + cardFaceClipPath.height / 2 - (textElement.height || 62) / 2,
                left: cardFaceClipPath.left,
                width: textWidth,
              });
              textElement.clipPath = cardFaceClipPath;
            }

            addUserTextHandlers(textElement, INITIAL_TEXT_MESSAGE, defaultColor, canvasRef.current);

            canvasRef.current.add(textElement);
            canvasRef.current.setActiveObject(textElement);
          }
          removeEditableAreaButtons(canvas);
        } else if (options?.target?.data?.type === CanvasDataTypes.UserZoneAddWamButton) {
          setIsWamDrawerOpen(appDispatch);
        }
        if (editableAreaObject) {
          editableAreaObject.isModified = true;
        }
      });

      // Type any was added here because we were blocked by build errors when publishing.  We need
      // to go back and figure out the correct type, and why our linting isn't catching the error
      // when removing this type casting.
      canvas.on('selection:updated', (options: any) => {
        const selectedObj = options.selected && options.selected[0];
        if (selectedObj) {
          onObjectSelection(selectedObj);
        }
      });

      canvas.on('text:changed', (event) => {
        const textbox = event.target;
        if (!textbox || !isEditableText(textbox)) {
          return;
        }
        onEditableTextChange(textbox);
      });

      // Same as the above comment about type any
      canvas.on('object:modified', (options: any) => {
        if (options?.action === 'rotate') {
          setIsRotationDrawerOpen(appDispatch);
        }
      });

      canvas.setDimensions(canvasDimensionsWithBleed);
      if (cardFace.originalCanvasJson) {
        const scaledCanvasJson = useCanvasScalingFactor(
          cardFace.originalCanvasJson,
          cardFace.type,
          cardState,
          projectTypeCode as string,
        );

        scaledCanvasJson.objects.forEach((obj: FabricObject) => {
          if (obj && obj.type === 'image') {
            (obj as fabric.Image).crossOrigin = 'anonymous';
            if (IS_FE_CROPPING_ENABLED) {
              // If the image has been cropped, replace the src with the s3 cropped url.
              setCroppedSrc(obj as CustomFabricImage);
            }
          }
          // Get dynamic_texboxes values from the template for photoTextZones
          if (obj && obj.name?.startsWith(CanvasDataTypes.PhotoTextZone)) {
            const textbox = obj as FabricTextBox;
            const templateTextField = findTextById(cardState.cardFacesList, textbox.data.ID);
            textbox.CanResizeTextArea = templateTextField?.CanResizeTextArea;
          }
        });
        // the photozone is created first, then the img is attached
        const myPhotozone = {} as fabric.Group;

        canvas.loadFromJSON(
          scaledCanvasJson,
          () => {
            canvas.renderAll.bind(canvas);
            canvas
              .getObjects('textbox')
              .filter((obj) => obj.data?.type === CanvasDataTypes.EditableText)
              .forEach((fabricItem) => {
                const editableText = fabricItem as fabric.Textbox;
                const editIconScale = getSizeAfterScaling(40, canvas);
                const maxLines = editableText.data?.maxLines;
                const maxFontSize = editableText.data?.maxFontSize;
                // using the fabric options as the settings since this was already populated with the settings in the first card load.
                const settings = fabricItem as unknown as EditableTextSettings;
                getEditableTextButton(settings, editIconScale).then((icon) => {
                  setEditableTextHandlers(icon, editableText, maxFontSize, maxLines, cardState);
                  canvas.add(icon);
                  canvas.bringToFront(icon);
                  canvas.requestRenderAll();
                });
              });
            cardState.cardFacesList.map((face) => addEditableAreas(face, cardState));
          },
          (jsonObject: ObjectJson, fabricItem: FabricObject) => {
            if (fabricItem) {
              if (fabricItem.type === 'image') {
                hideMiddleControls(fabricItem);
              }

              if (fabricItem.data?.type === 'userUploadedImage') {
                clipObject(myPhotozone[(fabricItem as CustomFabricObject).imgToZoneId], fabricItem);
              }

              if (fabricItem.name?.startsWith('photozone')) {
                myPhotozone[(fabricItem as CustomFabricObject).zoneId] = fabricItem;
              }

              if (fabricItem.name?.startsWith(CanvasDataTypes.PhotoTextZone) && fabricItem.data?.hasContent) {
                const textbox = (fabricItem as fabric.Group)._objects[0];
                textbox?.on('changed', () => {
                  toggleTamWarning(textbox as fabric.Textbox, fabricItem as fabric.Group, appDispatch, t);
                });
              }

              if (fabricItem.isType('textbox')) {
                (fabricItem as fabric.Textbox).editable = appState.hasMouse;
                fabricItem.selectable = true;

                if (fabricItem.name?.startsWith('userTextbox')) {
                  lockEditableSize(fabricItem as fabric.Textbox, cardState, activeCanvas, appDispatch, t);
                }

                if (fabricItem.data?.type === CanvasDataTypes.UserText) {
                  const defaultTextMsg = `${t('cardEditor.userTextDefaultMessage')}`;
                  const defaultColor = isUK ? '#333333' : initializedDataState.defaultColor || BrandColors.Black;
                  addUserTextHandlers(fabricItem as fabric.Textbox, defaultTextMsg, defaultColor, canvas);
                }
              }

              if (fabricItem.isType('group')) {
                fabricItem.setControlsVisibility({
                  mt: false,
                  mb: false,
                  ml: false,
                  mr: false,
                  bl: false,
                  br: false,
                  tl: false,
                  tr: false,
                  mtr: false,
                });
              }
            }
          },
        );
      } else {
        const backgroundSrc = cardFace.backgroundImage.replaceAll('\\', '/');
        if (!backgroundSrc || backgroundSrc == '' || backgroundSrc.includes('?w=0')) {
          setIsSystemErrorOpen(appDispatch, true);
          return;
        }
        fabric.Image.fromURL(
          `${backgroundSrc}?w=${imageDimensionsWithBleed.width}`,
          (img) => {
            // by setting the options from the imageDimensionsWithBleed, the height and width are also set
            // and this causes the image to be cropped since fabric 2.0.0
            // we use the scaling method to obtain the desired result
            // the rest of the options are set below, in the last param of fabric.Image.fromURL
            img.scaleToWidth(imageDimensionsWithBleed.width);
            canvas.setBackgroundImage(img, () => {
              // as of fabric 2 it is recommended to use requestRenderALl instead of renderAll
              canvas.requestRenderAll();
              //cardStore.changesSinceLastSave = 0;
            });
          },
          {
            left: imageDimensionsWithBleed.left,
            top: imageDimensionsWithBleed.top,
            fill: 'transparent',
            originX: 'left',
            originY: 'top',
            crossOrigin: 'anonymous',
          },
        );
      }

      if (cardState.cardFormat === 'landscape' && cardFace.type === 'inside') {
        const horizontalLine = new fabric.Rect({
          top: canvasDimensionsWithBleed.height / 2,
          width: cardFace.dimensions.width,
          height: 2,
          fill: config.cardEditor?.foldLineColor,
          hasControls: false,
          evented: false,
          selectable: false,
          data: { type: CanvasDataTypes.FoldLine },
        });
        canvas.add(horizontalLine);
        canvas.renderAll();
      }

      if (cardState.cardFormat === 'portrait' && cardFace.type === 'inside') {
        const verticalLine = new fabric.Rect({
          left: canvasDimensionsWithBleed.width / 2,
          height: cardFace.dimensions.height,
          width: 2,
          fill: config.cardEditor?.foldLineColor,
          hasControls: false,
          evented: false,
          selectable: false,
          data: { type: CanvasDataTypes.FoldLine },
        });
        canvas.add(verticalLine);
        canvas.renderAll();
      }
    },
  });

  const updateSize = useCallback(() => {
    const { width: canvasWidth = 0, height: canvasHeight = 0 } = canvasRef.current || {};
    const viewportWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth;

    const deviceType = viewportWidth > 1140 ? 'desktop' : viewportWidth > 768 ? 'tablet' : 'mobile';

    const scale = calculateAdjustedScale({
      canvasWidth,
      canvasHeight,
      deviceType,
    });

    const scaledWidth = canvasWidth * scale;
    const scaledHeight = canvasHeight * scale;

    if (canvasRef.current) {
      const zoom = canvasRef.current.getZoom() * scale;
      canvasRef.current.setDimensions({
        width: scaledWidth,
        height: scaledHeight,
      });
      canvasRef.current.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
      canvasRef.current.renderAll();
    }
  }, [canvasRef, containerRef]);

  useEffect(() => {
    if (isAndroid && window.visualViewport) {
      initialViewportHeight = window.visualViewport.height;
    }

    const handleResize = () => {
      updateSize();
    };

    const handleVisualViewportResize = () => {
      if (window.visualViewport) {
        if (isAndroid) {
          const isKeyboardOpen = window.visualViewport.height < initialViewportHeight * 0.8;
          if (isKeyboardOpen) {
            return;
          }
        }
        updateSize();
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleVisualViewportResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);

      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleVisualViewportResize);
      }
    };
  }, [updateSize]);

  const onEditingStarted = useCallback(() => {
    setHasEditingStarted(true);
  }, []);

  useEffect(() => {
    if (showInstructions) {
      activeCanvas?.current?.on('mouse:down', onEditingStarted);
    }

    return () => {
      activeCanvas?.current?.off('mouse:down', onEditingStarted);
    };
  }, [activeCanvas, showInstructions]);

  useEffect(() => {
    if (activeCanvas?.current) {
      const photoTextZones = activeCanvas.current.getObjects().filter((obj) => obj.name?.startsWith('photoTextZone'));
      photoTextZones.forEach((zone) => {
        const warningIcon = getObjectByName(`${zone.name}-warning-icon`, activeCanvas.current as fabric.Canvas);
        if (warningIcon?.visible) {
          zone.set('borderColor', colors.error);
        }
      });
    }
  }, [activeCanvas]);

  useEffect(() => {
    if (!activeCanvas?.current) return;

    const canvas = activeCanvas.current;
    let isProcessingEdit = false;

    const handleMouseDown = (opt: fabric.IEvent<Event>) => {
      if (isProcessingEdit) return;

      const target = opt.target;
      if (target?.type === 'textbox') {
        setHasEditingStarted(true);

        const isEdtableTextObject =
          !target.data?.isFixed ||
          target.data?.CanResizeTextArea ||
          target.data?.CanDeleteTextArea ||
          target.data?.displayFontToolbox;

        if (target.data?.type === CanvasDataTypes.EditableText && !isEdtableTextObject) {
          isProcessingEdit = true;

          const textbox = target as fabric.Textbox;

          if (textbox.isEditing) {
            textbox.exitEditing();
          }

          textbox.enterEditing();
          textbox.selectAll();
          canvas.renderAll();

          if (textbox.hiddenTextarea) {
            textbox.hiddenTextarea.focus();

            if (isAndroid) {
              textbox.hiddenTextarea.setAttribute('inputmode', 'text');
              textbox.hiddenTextarea.click();

              const touch = new TouchEvent('touchstart', {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              textbox.hiddenTextarea.dispatchEvent(touch);
            }
          }

          isProcessingEdit = false;
        } else if (target.type === 'textbox' && target.data?.type === CanvasDataTypes.PhotoTextZone) {
          handleTemporaryTextEditor(canvas);
        }

        onObjectSelection(target);
      }
    };

    canvas.off('mouse:down');
    canvas.on('mouse:down', handleMouseDown);

    return () => {
      canvas.off('mouse:down', handleMouseDown);
    };
  }, [activeCanvas, isAndroid, onObjectSelection, setHasEditingStarted]);

  const noInstructionsClassName = !showInstructions ? styles['card-face__no-instructions'] : '';

  return (
    <div className={`${classes} ${noInstructionsClassName}`}>
      <div className={styles['card-container']} ref={containerRef}>
        {isOneToMany && (
          <div className={styles['beta-label-container']}>
            <Pill title="Beta" addClass={styles['beta-label']} />
          </div>
        )}
        <div
          aria-live="polite"
          className={styles['canvas-wrapper']}
          tabIndex={0}
          id={`${cardFace.faceNumber}_TABPANEL`}
          role="tabpanel"
          aria-labelledby={`${cardFace.faceNumber}_TAB`}
          data-testid={`canvas-wrapper-${cardFace.faceNumber}`}
        >
          <FabricCanvas canvasRef={canvas} />
          {projectTypeCode === CardType.SAS && cardState?.activeCardIndex > 0 && (
            <div
              className={`${
                cardState.cardFormat === 'landscape' ? styles['landscape-instructions'] : styles['instructions']
              } ${hasEditingStarted ? styles['instructions__hidden'] : ''}`}
            >
              <Typography
                variant={TypographyVariants.Headline6}
                color={BrandColors.Black}
                addClass={styles['instructions-text']}
              >
                {t('cardFace.personalMessageInside')}
              </Typography>
            </div>
          )}
          {projectTypeCode === CardType.SAS && cardState?.activeCardIndex === 0 && (
            <div
              className={`${
                cardState.cardFormat === 'landscape' ? styles['landscape-instructions'] : styles['instructions']
              } ${hasEditingStarted ? styles['instructions__hidden'] : ''}`}
              ref={instructionsRef}
            >
              <Typography
                variant={TypographyVariants.Headline6}
                color={BrandColors.Black}
                addClass={styles['instructions-text']}
              >
                {t('cardFace.personalMessageOutside')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CardFace.defaultProps = {
  addClass: '',
  useOverrideRef: false,
  parentRef: null,
};
