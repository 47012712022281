import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { removeEditableAreas } from '../components/card-editor/utils/remove-editable-areas';
import {
  setIsSavingSaveButton,
  setIsSystemErrorOpen,
  setIsToasterOpen,
  setPersonalizationData,
  useAppContext,
} from '../context/app-context';
import { CardContextState, useCardContext } from '../context/card-context';
import { updateProjectName, useInitializationDataContext } from '../context/data-context';
import { CardFaceData, ErrorResponse } from '../global-types';
import { savePersonalization } from '../services';
import { updateDraftName } from '../services/customization';
import { getTransformedPersonalizationData, validateImages } from '../utils';
import { PodCardPreview } from '../utils/analytics/analytics-types';
import { pushPodCardPreview } from '../utils/analytics/analytics-utils';
import { CustomFabricType } from '../utils/canvas';
import { useGeneratePreviews } from '../utils/previews/generate-preview-images';
import { copyCanvasObjects, restoreCanvasObjects } from '../utils/save-project';
import { didBackgroundImagesPopulate } from '../utils/utility';
import { setEnvelopeSearchParam } from '../views/editor/utils/search-param-utils';
import { useActiveCanvas } from './useActiveCanvas';
import { useFeatureFlags } from './useFeatureFlags';
import { useIsDigitalGreeting } from './useIsDigitalGreeting';
import { useIsOneToMany } from './useIsOneToMany';
import { useQueryParams } from './useQueryParams';
import { useSystemErrorHandling } from './useSystemErrorHandling';

type SaveProjectOptions = {
  shouldRestoreCanvas: boolean;
  isSaveButtonClicked?: boolean;
  generatePreviews?: boolean;
  isFirstSave?: boolean;
  isDraftSave?: boolean;
};
/**
 * Custom hook used to save a project's draft name and personalization.
 * After the project is saved, the card canvas gets restored to its original state.
 * @returns callbacks to save the current project and update draft name
 */
export const useSaveProject = () => {
  const { t } = useTranslation();
  const {
    initializedDataState: { data: initializedData },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const { generatePreviewImages } = useGeneratePreviews();
  const isDigitalGreeting = useIsDigitalGreeting();
  const {
    appState: { productQuantity },
    appDispatch,
  } = useAppContext();
  const [onSystemError] = useSystemErrorHandling();
  const canvas = useActiveCanvas();
  const isOneToMany = useIsOneToMany();
  const { search, pathname } = useLocation();
  const { SAVED_PROJECTS, GENERATE_FE_PREVIEWS = true } = useFeatureFlags();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const missingBackgroundImageError = t('editorView.missingBackgroundImageError');
  const searchParams = new URLSearchParams(search);

  const saveProject = useCallback(
    async (
      options: SaveProjectOptions = {
        shouldRestoreCanvas: false,
        isSaveButtonClicked: false,
        generatePreviews: false,
        isFirstSave: false,
        isDraftSave: true,
      },
    ) => {
      try {
        setIsSavingSaveButton(appDispatch, true);

        if (canvas?.current && canvas.current.getActiveObject()) {
          canvas.current.discardActiveObject();
          canvas.current.requestRenderAll();
        }

        if (options.isSaveButtonClicked && !searchParams.has('saveProjectDialog')) {
          queryParams.append('saveProjectDialog', 'true');
          navigate(`${pathname}?${queryParams.toString()}`, { replace: true });
        }
        const projectTypeCode = initializedData?.project_type_code;
        const projectId = initializedData?.project_id;

        if (!projectTypeCode || !projectId || !SAVED_PROJECTS) {
          return Promise.reject('Missing necessary project data.');
        }

        const cardStateCopy = { ...cardState };

        canvas?.current?.discardActiveObject();
        const canvasObjectsCopy = copyCanvasObjects(projectTypeCode, cardStateCopy.cardFacesList as CardFaceData[]);

        removeEditableAreas(cardStateCopy.cardFacesList as CardFaceData[]);

        const saveData = getTransformedPersonalizationData(cardStateCopy as CardContextState, projectTypeCode);

        if (options.shouldRestoreCanvas) {
          restoreCanvasObjects(cardStateCopy.cardFacesList as CardFaceData[], canvasObjectsCopy);
        }

        const faces = initializedData?.variables?.template_data?.Faces || [];
        validateImages(faces, onSystemError, missingBackgroundImageError);
        if (!didBackgroundImagesPopulate(saveData)) {
          setIsSystemErrorOpen(appDispatch, true);
          return;
        }

        if (!isDigitalGreeting && GENERATE_FE_PREVIEWS && options.generatePreviews) {
          await generatePreviewImages(saveData);
        }

        if (options.isFirstSave) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }

        if (options.isDraftSave) {
          await Promise.all([
            savePersonalization(saveData, projectId),
            saveDraftName(initializedData.name || 'Draft', true),
          ]);
        } else {
          await savePersonalization(saveData, projectId);
        }

        setPersonalizationData(appDispatch, saveData);

        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsSavingSaveButton(appDispatch, false);

        if (canvas?.current) {
          const textAdded = canvas.current.getObjects().some((obj) => {
            if (obj.type === 'textbox') {
              const textbox = obj as fabric.Textbox;
              const placeholderText = textbox.data?.placeholder || '';
              return textbox.text && textbox.text !== placeholderText && textbox.text.trim() !== '';
            }
            return false;
          });

          const photoAdded = canvas.current
            .getObjects()
            .some((obj) => obj.type === 'image' && obj.data?.customType === CustomFabricType.Image);

          const stickerAdded = canvas.current
            .getObjects()
            .some((obj) => obj.type === 'image' && obj.data?.customType === CustomFabricType.Sticker);

          const handwritingAdded = canvas.current
            .getObjects()
            .some((obj) => obj.type === 'image' && obj.data?.customType === CustomFabricType.Handwriting);

          if (textAdded || photoAdded || stickerAdded || handwritingAdded) {
            const podCardPreviewData: Omit<PodCardPreview, 'event_id'> = {
              event: 'pod_card_preview',
              text_added: textAdded,
              photo_added: photoAdded,
              sticker_added: stickerAdded,
              handwriting_added: handwritingAdded,
            };
            pushPodCardPreview(podCardPreviewData);
          }

          if (!textAdded && !photoAdded && !stickerAdded && !handwritingAdded) {
            const podCardPreviewData: Omit<PodCardPreview, 'event_id'> = {
              event: 'pod_card_preview',
              text_added: false,
              photo_added: false,
              sticker_added: false,
              handwriting_added: false,
            };
            pushPodCardPreview(podCardPreviewData);
          }
        }

        setEnvelopeSearchParam(searchParams, search, isOneToMany);

        return saveData;
      } catch (error) {
        setIsToasterOpen(appDispatch, {
          title: t('saveProject.title'),
          children: t('saveProject.description'),
          variant: ToastVariants.Error,
        });
        onSystemError(error as ErrorResponse);
        setIsSavingSaveButton(appDispatch, false);
        throw error;
      }
    },
    [
      initializedData?.project_type_code,
      initializedData?.project_id,
      initializedData?.variables?.template_data?.Faces,
      cardState,
      onSystemError,
    ],
  );

  /**
   * Update a project's name
   * @param draftName the new name for the project
   * @param isDraft
   */
  const saveDraftName = useCallback(
    (draftName: string, isDraft: boolean) => {
      const projectId = initializedData?.project_id;
      if (!projectId) {
        return;
      }

      return updateDraftName(draftName, projectId, productQuantity ?? '1', isDraft)
        .then(() => updateProjectName(initializationDataDispatch, draftName))
        .catch((error) => onSystemError(error));
    },
    [initializedData?.project_id, onSystemError],
  );

  return { saveProject, saveDraftName };
};
