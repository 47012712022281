import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@hallmark/web.core.buttons.loading-button';
import { SaveButton } from '@hallmark/web.core.buttons.save-button';
import { BrandColors } from '@hallmark/web.styles.colors';
import { CEIconNames, IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAppContext } from '../../context/app-context';
import { setFirstSaveAttempt, setSavingState } from '../../context/app-context/app-context-actions';
import { useCardContext } from '../../context/card-context';
import { useCropContext } from '../../context/crop-context';
import { useInitializationDataContext } from '../../context/data-context';
import { useFeatureFlags, useSaveProject } from '../../hooks';
import { config } from '../../regional-config';
import { PodSaveCard } from '../../utils/analytics/analytics-types';
import { pushPodSaveCard } from '../../utils/analytics/analytics-utils';
import styles from './save-project-link.module.scss';

export const SaveProjectLink = () => {
  const { appState, appDispatch } = useAppContext();
  const { cardState } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { isSavingSaveButton } = appState;
  const { t } = useTranslation();
  const { saveProject } = useSaveProject();
  const { isSavingCroppedImage } = useCropContext();
  const { SAVED_PROJECTS } = useFeatureFlags();

  useEffect(() => {
    localStorage.removeItem('hasSavedProject');
  }, []);

  const handleClick = async () => {
    const saveCardEventData: Omit<PodSaveCard, 'event_id'> = {
      event: 'pod_save_card',
      item_id: cardState?.projectId,
      item_name: cardState?.cardTemplateName,
      card_template_id: cardState?.cardTemplateId,
    };
    pushPodSaveCard(saveCardEventData);

    const hasSavedProject = localStorage.getItem('hasSavedProject');
    const isFirstSave = hasSavedProject === null;

    if (isFirstSave) {
      setFirstSaveAttempt(appDispatch, true);
    }
    setSavingState(appDispatch, true);

    try {
      if (SAVED_PROJECTS && initializedData?.project_type_code !== 'S') {
        await saveProject({
          shouldRestoreCanvas: true,
          isSaveButtonClicked: true,
          generatePreviews: true,
          isDraftSave: true,
          isFirstSave,
        });

        if (isFirstSave) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          localStorage.setItem('hasSavedProject', 'true');
          setFirstSaveAttempt(appDispatch, false);
        }
      }
    } catch (error) {
      if (isFirstSave) {
        setFirstSaveAttempt(appDispatch, false);
      }
    } finally {
      setSavingState(appDispatch, false);
    }
  };

  const renderSaveButton = () => {
    const buttonConfig = config.saveProjects.saveButton;

    if (buttonConfig.type === 'SaveButton') {
      return (
        <SaveButton
          isSaving={isSavingSaveButton || isSavingCroppedImage}
          onClick={handleClick}
          iconName={CEIconNames.Save}
          savingText={t('header.saving') ?? ''}
        />
      );
    }

    return (
      <LoadingButton
        testId="save-project-loading-button"
        addClass={styles['save-project-link']}
        click={handleClick}
        isLoading={isSavingSaveButton || isSavingCroppedImage}
        loadingColor={BrandColors.Disabled}
        defaultColor={BrandColors.Blue}
        loadingIconName={IconNames.SyncSyncInactive}
        isIconSpinning={true}
        loadingText={t('header.saving')}
        defaultText={t('header.save')}
      />
    );
  };

  return renderSaveButton();
};
