import { isMobile, isTablet } from 'react-device-detect';
import { AppContextState, AppToasterState, ImageEditDrawerModes } from './app-context-types';

export const toasterInitialState: AppToasterState = {
  isOpen: false,
  title: '',
  children: '',
};

export const initState: AppContextState = {
  isMobileApp: isMobile,
  isTabletApp: isTablet,
  mobileAppToken: '',
  isSidePanelVisible: false,
  loadedComponent: null, // it refers to what's in the sidePanel parent component
  loadedComponentId: '',
  loader: { isLoading: false, title: '' },
  editState: null,
  editMode: null,
  useMock: false,
  renderFontsOnServer: true,
  hasMouse: true,
  // hasMouse: true,
  isIE: false, // currently not in use
  isMember: false, // default needs to be false
  loginTemplate: null,
  action: '',
  isPhotoZoneSelected: false,
  isCardCaption: false,
  isTextDrawerOpen: false,
  isAlignmentDrawerOpen: false,
  isFontDrawerOpen: false,
  isSizeDrawerOpen: false,
  isColorDrawerOpen: false,
  isColorPalleteDrawerOpen: false,
  isSystemErrorOpen: false,
  errorMessages: [],
  isOrderDrawerOpen: false,
  isProjectSaved: false,
  isScaleDrawerOpen: false,
  isImageUploadDrawerOpen: false,
  isImageEditDrawerOpen: false,
  imageEditDrawerMode: ImageEditDrawerModes.UserImage,
  isWamDrawerOpen: false,
  isDeleteConfirmationDialogOpen: false,
  isResetConfirmationDialogOpen: false,
  isClearConfirmationDialogOpen: false,
  isImageUploadToPhotoZoneOpen: false,
  isStickerPickDrawerOpen: false,
  isRotationDrawerOpen: false,
  personalizationData: [],
  photoTrayImages: [],
  localizationObj: [],
  localizationsLoaded: false,
  objectsAdded: {
    firstPage: {
      texts: 0,
      images: 0,
      decorations: 0,
    },
    secondPage: {
      texts: 0,
      images: 0,
      decorations: 0,
    },
  },
  cartUtility: null,
  accountId: '',
  sentiments: {},
  exitUtility: null,
  toaster: toasterInitialState,
  moreColorsAdded: [],
  exitUrl: '/',
  isSendToMe: false,
  triggeringElementRef: null,
  csrfToken: '',
  // jwt: '',
  isRedirectDialogOpen: false,
  redirectUrl: '',
  productQuantity: '1',
  cardFacesLoaded: false,
  isSaving: false,
  isFirstSaveAttempt: false,
  isSavingSaveButton: false,
  dialogType: null,
  previewImages: [],
};
